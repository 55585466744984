import React, { Fragment } from 'react';
import './EventTime.less';

export default function EventTime({ date, fromTime, toTime }) {
	return (
		<Fragment>
			<a name={`${date}`}></a>
			<time className="dt-start text-1" dateTime={`${date}T${fromTime}Z`} />
			<time className="dt-end" dateTime={`${date}T${toTime}Z`} />
		</Fragment>
	);
}
