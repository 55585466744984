import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { Helmet } from 'react-helmet';
import contexts from '../../context/contexts';
import Item from '../../widgets/Item';

const {
	useStore,
	useDispatch,
	makeDispatcher
} = contexts.links;

const sortByTitle = (a, b) =>
	a.title > b.title ? 1 : a.title < b.title ? -1 : 0;

const Links = () => {
	const dispatch = makeDispatcher(useDispatch());
	const { links } = useStore();

	useEffect(() => {
		if (!links.data.length) {
			dispatch({ type: 'FETCH_LINKS' });
		}
	}, []); // the [] is for when mounting only

	if (!links.finishedLoading) {
		return null;
	}

	if (!links.data.length) {
		return (
			<div className="links">
				<Helmet>
					<meta charSet="utf-8" />
					<title>
						An Góilín Traditional Singers - Links
					</title>
				</Helmet>
				<h1>Links</h1>
				<div className="box-content box">
					Coming soon!
				</div>
			</div>
		);
	}

	const items = links.data
		.sort(sortByTitle)
		.map(download => 
			<Item
				key={download.src}
				description={download.description}
				by={download.by}
				title={download.title}
				tags={download.tags}
				url={download.src}
				icon="bi-arrow-up-right-square-fill"
			/>
		);

	return (
		<div className="links">
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					An Góilín Traditional Singers - Links
				</title>
			</Helmet>
			<h1>Links</h1>
			{items}
		</div>
	);
};

export default Links;
