import React, { useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import Event from '../calendar/Event';
import contexts from '../../context/contexts';
import './Home.less';
import gclef from '../../../images/angoilin.svg';

const {
	useStore: useEventsStore,
	useDispatch: useEventsDispatch,
	makeDispatcher: makeEventsDispatcher
} = contexts.events;

const {
	useStore: useMessagesStore,
	useDispatch: useMessagesDispatch,
	makeDispatcher: makeMessagesDispatcher
} = contexts.messages;

export default function Home() {
	const eventsDispatch = makeEventsDispatcher(useEventsDispatch());
	const messagesDispatch = makeMessagesDispatcher(useMessagesDispatch());
	const { events } = useEventsStore();
	const { messages } = useMessagesStore();

	useEffect(() => {
		if (!events.data.length) {
			eventsDispatch({ type: 'FETCH_EVENTS_NEXT' });
		}
		if (!messages.data.length) {
			messagesDispatch({ type: 'FETCH_MESSAGES' });
		}
	}, []); // the [] is for when mounting only

	if (!events.finishedLoading || !messages.finishedLoading) {
		return null;
	}
	const fhf = events.data.find(a => a.title.match(/frank harte festival/i))
	const panels = [];
	const className = classnames('box-content box next-event', { fhf });
	if (events.data.length) {
		const nextEvent = events.data[0];
		panels.push(
			<div key="next-event" className={className}>
				<Event event={nextEvent} image />
			</div>
		);
	} else if (!fhf) {
		// No events, on break
		panels.push(
			<div key="next-event" className={className}>
				<img src={gclef} className="gclef" />
				<Link to="/about">An Góilín</Link> is currently on a break and there are no events
				in the <Link to="/calendar">Calendar</Link>. The season runs from September to July,
				so come back soon to see the next season's line up.
			</div>
		);
	}
	if (fhf) {
		panels.push(
			<div key="fhf" className={className}>
				<Event
					event={fhf}
					more="https://frankhartefestival.ie"
					artsCouncil
					image
				/>
			</div>
		);
	}

	if (messages.data.length) {
		const message = messages.data[0];
		panels.push(
			<div key="messages" className="message box-content box">
				<Alert variant="light">
					<ReactMarkdown>
						{message.message}
					</ReactMarkdown>
				</Alert>
			</div>
		);
	}

	return (
		<div className="home">
			<div className={classnames('panels', { fhf })}>
				{panels}
			</div>
		</div>
	);
}
