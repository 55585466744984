import React from 'react';
import Badge from 'react-bootstrap/Badge';

export default function Tags({ location, tags }) {
	const resp = [];
	if (location === 'zoom') {
		resp.push(<Badge key={location} variant="info">{location}</Badge>);
	} else if (location === 'venue') {
		resp.push(<Badge key={location} variant="warning">teachers-club</Badge>);
	} else if (location === 'none') {
		resp.push(<Badge key={location} variant="dark">closed</Badge>);
	}

	for (let tag of tags) {
		let variant = 'secondary';
		if (tag.startsWith('special')) {
			variant = 'danger';
		} else if (tag === 'as-gaeilge') {
			variant = 'primary';
		} else if (tag === 'open-singing-session') {
			variant = 'success';
		}
		if (tag === 'none') {
			tag = 'no-club';
		}
		resp.push(
			<Badge key={tag} bg={variant}>{tag}</Badge>
		);
	}
	return resp;
}
