import React from 'react';
import './EventDate.less';

export default function EventDate({ type, day, month }) {
	return (
		<div className="event-date">
			<span className="event-day">{day}</span>
			<span className="event-month">{month}</span>
		</div>
	);
}
