import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { Helmet } from 'react-helmet';
import contexts from '../../context/contexts';
import Item from '../../widgets/Item';
import './Downloads.less';

const {
	useStore,
	useDispatch,
	makeDispatcher
} = contexts.downloads;

const sortByTitle = (a, b) =>
	a.title > b.title ? 1 : a.title < b.title ? -1 : 0;

const Downloads = () => {
	const dispatch = makeDispatcher(useDispatch());
	const { downloads } = useStore();

	useEffect(() => {
		if (!downloads.data.length) {
			dispatch({ type: 'FETCH_DOWNLOADS' });
		}
	}, []); // the [] is for when mounting only

	if (!downloads.finishedLoading) {
		return null;
	}

	if (!downloads.data.length) {
		return (
			<div className="downloads">
				<Helmet>
					<meta charSet="utf-8" />
					<title>
						An Góilín Traditional Singers - Downloads
					</title>
				</Helmet>
				<h1>Downloads</h1>
				<div className="box-content box">
					Coming soon!
				</div>
			</div>
		);
	}

console.log('downloads', downloads.data);

	const items = downloads.data
		.sort(sortByTitle)
		.map(download =>
			<Item
				key={download.src}
				description={download.description}
				by={download.by}
				title={download.title}
				image={download.image}
				tags={download.tags}
				url={download.src}
				icon="bi-download"
			/>
		);

	return (
		<div className="downloads">
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					An Góilín Traditional Singers - Downloads
				</title>
			</Helmet>
			<h1>Downloads</h1>
			{items}
		</div>
	);
};

export default Downloads;
