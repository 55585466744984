import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Button, Form, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import contexts from '../../context/contexts';

const {
	useStore,
	useDispatch,
	makeDispatcher
} = contexts.login;

/**
 * For development, use https and v.goilin.
 *
 * 1. npm start
 * 1. https://v.goilin:3000
 * 1. Login, and copy the sessionId from POST into new sessionId for current
 * domain in Application > Cookies.
 */
const Login = () => {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const dispatch = makeDispatcher(useDispatch());
	const { login } = useStore();
	const [invalid, setInvalid] = useState(false);
	const navigate = useNavigate();

	const credentials = sessionStorage.getItem('credentials');
	const authenticated = !!credentials;

	function handleSubmit(ev) {
		ev.preventDefault();
		dispatch({
			type: 'FETCH_LOGIN',
			onError: (message) => {
				console.error('login error', message);
				setInvalid(true);
			},
			onSuccess: () => {
				setInvalid(false);
			},
			payload: {
				username,
				password
			}
		});
	}

	function validateForm() {
		return username.length > 0 && password.length > 0;
	}

	useEffect(() => {
		const user = login.data;
		if (authenticated && user && user.logged) {
			return navigate('/calendar');
		}
	}, [authenticated, login])

	return (
		<div className="login">
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					An Góilín Traditional Singers - Login
				</title>
			</Helmet>
			<h1>Login</h1>
			<div className="box-content box">
				<Form onSubmit={handleSubmit}>
					<Form.Group className="mb-3" controlId="formBasicEmail">
						<Form.Label>Username</Form.Label>
						<Form.Control
							type="username"
							placeholder="Enter username"
							onChange={(ev) => setUsername(ev.target.value)}
						/>
					</Form.Group>
					<Form.Group className="mb-3" controlId="formBasicPassword">
						<Form.Label>Password</Form.Label>
						<Form.Control
							type="password"
							placeholder="Password"
							onChange={(ev) => setPassword(ev.target.value)}
						/>
					</Form.Group>
					<Form.Group className="mb-3">
						<Button
							variant="primary"
							type="submit"
							disabled={!validateForm()}
						>
							Login
						</Button>
					</Form.Group>
					{ invalid && (
						<Alert variant="danger">
							Invalid username or password.
						</Alert>
					)}
				</Form>
			</div>
		</div>
	);
};

export default Login;
